
/*!
 * jQuery Even Heights plugin
 * Author: Glen Cheney
 * Modified: 2014-03-08
 * Dependencies: jQuery 1.2.6+
 * Sets a jQuery collection to all be the same height
 * If you need to set multiple collection, please use `$.evenHeights( collectionsArray )`
 * because it is much faster
 */

(function( $ ) {
  'use strict';

  function getTallest( $elements ) {
    var tallest = 0;
    $elements.each(function() {
      if ( this.offsetHeight > tallest ) {
        tallest = this.offsetHeight;
      }
    });
    return tallest;
  }


  $.fn.evenHeights = function() {
    return this.css( 'height', '' ).css( 'height', getTallest( this ) );
  };


  /**
   * For groups of elements which should be the same height. Using this method
   * will create far less style recalculations and layouts.
   * @param {Array.<jQuery>} groups Array of jQuery collections.
   * @return {Array.<number>} An array containing the pixel value of the
   *     tallest element for each group.
   */
  $.evenHeights = function( groups ) {
    var winners = [];

    // First, reset the height for every element.
    // This is done first, otherwise we dirty the DOM on each loop!
    $.each(groups, function( i, $elements ) {
      $elements.css( 'height', '' );
    });

    // Now, measure heights in each group and save the tallest value. Instead of
    // setting the height value for the entire group, save it. If it were set,
    // the next iteration in the loop would have to recalculate styles in the DOM
    $.each(groups, function( i, $elements ) {
      winners.push( getTallest( $elements ) );
    });

    // Lastly, set them all.
    $.each(groups, function( i, $elements ) {
      $elements.css( 'height', winners[ i ] );
    });

    return winners;
  };
})(jQuery);


(function($) {

  var $grid = $('.brands-wrap');
  var brand_targets = [];
  var brand_products = [];

  $(window).on('resize', function() {
    if(!$('.navigation-toggle').is(':visible')) {
      $grid.find('.brand').evenHeights();
    } else {
      $grid.find('.brand').css('height', 'auto');
    }
  });
  $(window).trigger('resize');

  var setVars = function() {
    $brand_target = $('.js-brand_target');
    $brand_products = $('.js-brand_products');
  };

  var initShuffle = function() {
    // instantiate the plugin
    $grid.shuffle({
      itemSelector: '.brand',
      speed : 250,
      easing : 'cubic-bezier(0.165, 0.840, 0.440, 1.000)', // easeOutQuart
    });
  };

  var hasActiveFilters = function() {
    return brand_targets.length > 0 || brand_products.length > 0;
  };

  var valueInArray = function(search, from) {
    var isInArray = false;

    $.each(search, function(key, id) {
      if ($.inArray(id.toString(), from) !== -1) {
        isInArray = true;
      }
    });
    return isInArray;
  };

  var itemPassesFilters = function(data) {

    // If a target filter is active
    if ( brand_targets.length > 0 && !valueInArray(data.brand_target, brand_targets) ) {
      return false;
    }

    // If a category filter is active
    if ( brand_products.length > 0 && !valueInArray(data.brand_products, brand_products) ) {
      return false;
    }

    return true;
  };

  var filter = function() {
    if ( hasActiveFilters() ) {
      $grid.shuffle('shuffle', function($el) {
        return itemPassesFilters( $el.data() );
      });
    } else {
      $grid.shuffle( 'shuffle', 'all' );
    }
  };


  var initFilters = function() {
    // brand_target
    $brand_target.find('input').on('change', function() {
      var $checked = $brand_target.find('input:checked'),
      groups = [];

      // At least one checkbox is checked, clear the array and loop through the checked checkboxes
      // to build an array of strings
      if ($checked.length !== 0) {
        $checked.each(function() {
            groups.push(this.value);
        });
      }
      brand_targets = groups;

      filter();
    });

    // brand_products
    $brand_products.find('select').on('change', function() {
      var $checked = $brand_products.find('option:selected'),
      groups = [];

      // At least one checkbox is checked, clear the array and loop through the checked checkboxes
      // to build an array of strings
      if ($checked.length !== 0) {
        $checked.each(function() {
          if(this.value !== '') {
            $.merge(groups, JSON.parse(this.value));
          }
        });
      }
      $.each(groups, function(i, val) {
        groups[i] = val.toString();
      });
      brand_products = groups;

      filter();
    });

  };





  var init = function() {
    setVars();
    initFilters();
    initShuffle();
  };

  init();


})(jQuery);
